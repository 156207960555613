import React, { useRef, useState } from "react";
import classnames from "classnames";
import CustomCursor from "custom-cursor-react";
import { PageFooter, PageHeader, BottomCTA, Subscribe } from "../../components";

import "custom-cursor-react/dist/index.css";

import "normalize.css";
import "../../global/styles/base.scss";
import "./page-layout.scss";

const CURSOR_COLOR = "#6e4aff";

const PageLayout = ({ children, className, isErrorPage, isLogin, isLight }) => {
  const [noScroll, setNoScroll] = useState(false);
  const hasFooter = !isErrorPage && !isLogin;
  const miniNav = isLogin;
  const pageRef = useRef();

  return (
    <div
      className={classnames("page", {
        "page--light": isLight,
        [className]: className,
        noscroll: noScroll,
      })}
      ref={pageRef}
    >
      <PageHeader miniNav={miniNav} setNoScroll={setNoScroll} isLight={isLight} />
      <main className="page__content">
        {children}
        {hasFooter && <PageFooter />}

        <CustomCursor
          targets={[".btn", "a[href]"]}
          customClass="custom-cursor"
          dimensions={60}
          fill="TRANSPARENT"
          opacity={1}
          strokeColor="#1cb3bd"
          strokeWidth={2}
          smoothness={{
            movement: 0.2,
            scale: 0.1,
            opacity: 0.2,
          }}
          targetOpacity={0}
          targetScale={0}
        />
      </main>
    </div>
  );
};

export default PageLayout;
